var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"picker-marketing"},[_c('SpFinder',{ref:"finder",class:['shop-finder', { 'no-multiple': !_vm.multiple }],attrs:{"other-config":{
      'max-height': 460
    },"data":_vm.list,"fixed-row-action":true,"setting":{
      columns: [
        { name: 'ID', key: 'id', width: 120 },
        { name: '营销名称', key: 'title' }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import fetch from '../utils/fetch'

// 商铺列表
export function getShopList (query) {
  return fetch({
    url: '/xiudishop/getlist',
    method: 'get',
    params: query
  })
}

// 商铺保存
export function shopSave (query) {
  return fetch({
    url: '/xiudishop/save',
    method: 'post',
    params: query
  })
}

// 商铺删除
export function shopDelete (query) {
  return fetch({
    url: '/xiudishop/delete',
    method: 'post',
    params: query
  })
}

// 商铺详情
export function shopDetail (query) {
  return fetch({
    url: '/xiudishop/detail',
    method: 'get',
    params: query
  })
}

// 店员保存
export function staffSave (query) {
  return fetch({
    url: '/xiudishopstaff/save',
    method: 'post',
    params: query
  })
}

// 店员删除
export function staffDelete (query) {
  return fetch({
    url: '/xiudishopstaff/delete',
    method: 'post',
    params: query
  })
}

// 店员列表
export function staffList (query) {
  return fetch({
    url: '/xiudishopstaff/getlist',
    method: 'get',
    params: query
  })
}

// 员工详情
export function staffDetail (query) {
  return fetch({
    url: '/xiudishopstaff/detail',
    method: 'get',
    params: query
  })
}

// 组织架构 /xiudishop/shopOrganiz
export function shopOrganiz (query) {
  return fetch({
    url: '/xiudishop/shopOrganiz',
    method: 'get',
    params: query
  })
}

// 推广码访问日志
export function logList (query) {
  return fetch({
    url: '/h5app/wxapp/xiudi/promotion/log/create',
    method: 'get',
    params: query
  })
}

// 推广码用户访问日志
export function userLogList (query) {
  return fetch({
    url: '/xiudi/promotion/log/getuserlist',
    method: 'get',
    params: query
  })
}

// 计划创建
export function planCreate (query) {
  return fetch({
    url: '/xiudipromotionplan/save',
    method: 'post',
    params: query
  })
}

// 计划列表
export function planList (query) {
  return fetch({
    url: '/xiudipromotionplan/getlist',
    method: 'get',
    params: query
  })
}

// 计划详情
export function planDetail (query) {
  return fetch({
    url: '/xiudipromotionplan/detail',
    method: 'get',
    params: query
  })
}

// 计划统计
export function planStatistics (query) {
  return fetch({
    url: '/xiudipromotionplan/tj',
    method: 'get',
    params: query
  })
}

// 计划删除
export function planDelete (query) {
  return fetch({
    url: '/xiudipromotionplan/delete',
    method: 'post',
    params: query
  })
}


// 前端推广关系绑定
export function bindPromotion (query) {
  return fetch({
    url: '/h5app/wxapp/xiudi/promotion/relation/create',
    method: 'post',
    params: query
  })
}

// 目标保存
export function targetSave (query) {
  return fetch({
    url: '/xiudishopsalestarget/save',
    method: 'post',
    params: query
  })
}

// 目标列表
export function targetList (query) {
  return fetch({
    url: '/xiudishopsalestarget/getlist',
    method: 'get',
    params: query
  })
}

// 目标详情
export function targetDetail (query) {
  return fetch({
    url: '/xiudishopsalestarget/detail',
    method: 'get',
    params: query
  })
}

// 目标详情统计
export function targetStatistics (query) {
  return fetch({
    url: '/xiudishopsalestarget/tj',
    method: 'post',
    params: query
  })
}

// 目标删除
export function targetDelete (query) {
  return fetch({
    url: '/xiudishopsalestarget/delete',
    method: 'post',
    params: query
  })
}

// 用户分析摘要统计
export function userSummary (query) {
  return fetch({
    url: '/members/xiudisummary',
    method: 'get',
    params: query
  })
}

// 用户列表(原接口增加参数)
export function userList (query) {
  return fetch({
    url: '/members',
    method: 'get',
    params: query
  })
}

// 订单摘要统计
export function orderSummary (query) {
  return fetch({
    url: '/orders/xiudisummary',
    method: 'get',
    params: query
  })
}

// 订单列表(原接口增加参数)
export function orderList (query) {
  return fetch({
    url: '/orders',
    method: 'get',
    params: query
  })
}


// 计划统计用户数据导出
export function planUserExport (query) {
  return fetch({
    url: '/xiudipromotionplan/exportPlanUserData',
    method: 'get',
    params: query
  })
}

// 计划统计订单数据导出
export function planOrderExport (query) {
  return fetch({
    url: '/xiudipromotionplan/exportPlanOrderData',
    method: 'get',
    params: query
  })
}

// 商铺用户数据导出
export function shopUserExport (query) {
  return fetch({
    url: '/xiudishop/exportShopUserData',
    method: 'get',
    params: query
  })
}

// 商铺订单数据导出
export function shopOrderExport (query) {
  return fetch({
    url: '/xiudishop/exportShopOrderData',
    method: 'get',
    params: query
  })
}

// 目标详情统计导出
export function targetExport (query) {
  return fetch({
    url: '/xiudishopsalestarget/exportShopLxData',
    method: 'post',
    params: query
  })
}


// 扫码日志导出
export function logExport (query) {
  return fetch({
    url: '/xiudipromotionplan/exportPromotionLogData',
    method: 'get',
    params: query
  })
}

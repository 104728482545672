var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'wgt-slider': true,
    'padded': _vm.value.padded
  }},[(_vm.value.title || _vm.value.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.value.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.value.subtitle))])]):_vm._e(),_c('div',{staticClass:"wgt-bd",class:{
      'spaced': _vm.value.spaced,
      'padded': _vm.value.itemPadded
    }},[_c('SpImage',{staticClass:"placeholder-item",attrs:{"src":_vm.value.data.length > 0 ? _vm.value.data[0].imgUrl : null,"fit":"contain"}}),_c('el-carousel',{staticClass:"slider-container",attrs:{"arrow":"never","indicator-position":"none","interval":_vm.value.interval},on:{"change":function (e) {
          this$1.currentIndex = e
        }}},_vm._l((_vm.value.data),function(item,index){return _c('el-carousel-item',{key:index,staticClass:"slider-item",class:{
          'rounded': _vm.value.rounded,
          'padded': _vm.value.itemPadded
        }},[_c('SpImage',{attrs:{"src":item.imgUrl,"fit":"contain"}})],1)}),1),_c('div',{class:[
        'slider-pagination',
        _vm.value.dotLocation,
        _vm.value.shape,
        _vm.value.dotColor,
        {
          'cover': _vm.value.dotCover,
          'cover-padded': _vm.value.itemPadded
        }
      ]},[(_vm.value.dot)?_vm._l((_vm.value.data.length > 0 ? _vm.value.data : [1]),function(item,index){return _c('div',{key:("dot-item__" + index),class:['dot-item', { 'active': _vm.currentIndex == index }]})}):[_c('div',{staticClass:"pagination-count"},[_vm._v(" "+_vm._s(((_vm.currentIndex + 1) + "/" + (_vm.value.data.length > 0 ? _vm.value.data.length : 1)))+" ")])]],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'wgt-marquees': true,
    'padded': _vm.value.padded
  }},[(_vm.value.title || _vm.value.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.value.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.value.subtitle))])]):_vm._e(),_c('div',{staticClass:"wgt-bd",class:{
      'spaced': _vm.value.spaced
    },style:({
      'background': _vm.value.bgcolor
    })},[(_vm.value.direction == 'vertical')?[_c('el-carousel',{attrs:{"height":"30px","direction":"vertical","autoplay":true,"indicator-position":"none"}},_vm._l((_vm.value.dataContent),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"medium",style:({
              'color': _vm.value.fontcolor
            })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),1)]:[_c('marquee',{staticStyle:{"margin":"4px 0","display":"block"},attrs:{"scrolldelay":"200"}},_vm._l((_vm.value.dataText),function(item,index){return _c('span',{key:index,style:({
            'color': _vm.value.fontcolor
          })},[_vm._v(_vm._s(item.title))])}),0)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./attr-label.vue?vue&type=template&id=1a31b7d6&scoped=true&"
import script from "./attr-label.vue?vue&type=script&lang=js&"
export * from "./attr-label.vue?vue&type=script&lang=js&"
import style0 from "./attr-label.vue?vue&type=style&index=0&id=1a31b7d6&lang=scss&scoped=true&"
import style1 from "./attr-label.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a31b7d6",
  null
  
)

export default component.exports
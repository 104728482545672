var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'wgt-headline': true,
    'padded': _vm.value.padded
  }},[_c('div',{staticClass:"wgt-bd",class:{
      'spaced': _vm.value.spaced
    }},[_c('div',{class:[
        'wgt-content',
        {
          'center': _vm.value.float === 'center',
          'left': _vm.value.float === 'left'
        }
      ]},[_c('span',[_vm._v(" "+_vm._s(_vm.value.title)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }